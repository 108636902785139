/*
 * Copyright (C) 2024 Loglama.Net, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import Reflux from 'reflux';

import * as URLUtils from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import fetch from 'logic/rest/FetchProvider';

const SystemProcessingStore = Reflux.createStore({
  sourceUrl: (nodeId) => `/cluster/${nodeId}/processing`,

  pause(nodeId) {
    return fetch('POST', URLUtils.qualifyUrl(`${this.sourceUrl(nodeId)}/pause`))
      .then(
        () => {
          this.trigger({});
          UserNotification.success(`Message processing paused successfully in '${nodeId}'`);
        },
        (error) => {
          UserNotification.error(`Pausing message processing in '${nodeId}' failed: ${error}`,
            `Could not pause message processing in node '${nodeId}'`);
        },
      );
  },

  resume(nodeId) {
    return fetch('POST', URLUtils.qualifyUrl(`${this.sourceUrl(nodeId)}/resume`))
      .then(
        () => {
          this.trigger({});
          UserNotification.success(`Message processing resumed successfully in '${nodeId}'`);
        },
        (error) => {
          UserNotification.error(`Resuming message processing in '${nodeId}' failed: ${error}`,
            `Could not resume message processing in node '${nodeId}'`);
        },
      );
  },
});

export default SystemProcessingStore;
